<template>
    <section class="pa-0 formular">
        <v-container fluid class="pa-4 pb-16">
            <v-btn
                class="gold mb-3"
                dark small depressed dense
                @click="$refs.transactions.click()"
            >
                <v-icon small left>mdi-file-import-outline</v-icon>
                {{$t('transactions.dialog.import_file')}}
            </v-btn>
            <input v-show="false" type="file" accept="text/plain" ref="transactions" @change="upload_bank_statement">
            <Card card="BankTransactionCard" class="no-cursor-select"
                  :item="item"
                  :state="!/OTHER/.test(item.raw_data.type) ? '' : 'state-red'"
                  v-for="(item,i) in getTransactions.items" :key="i"
            />
            <v-pagination v-if="(filter.limit - getTransactions.total_count) < 0"
                          v-model="filter.page"
                          :length="Math.ceil(getTransactions.total_count / filter.limit)"
                          :total-visible="10"
                          color="gold"
                          class="py-3 pb-5"
            />
            <div v-else class="py-6"></div>
        </v-container>
        <v-container fluid style="position: fixed; bottom: 0; max-width: 100%;" :class="this.$vuetify.theme.dark ? 'black' : 'grey lighten-2'">
            <v-row align="center" no-gutters>
                <v-col cols="4"></v-col>
                <v-col cols="4" align="center">
                    <v-dialog
                        v-model="dialog_process"
                        persistent
                        max-width="640"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn @click="process_bank_statement"
                                dense depressed color="gold" class="white--text"
                                v-bind="attrs"
                                v-on="on"
                                   :disabled="!can_process"
                            >
                                <v-icon left>mdi-play-circle</v-icon>
                                {{ $t('common.record') }}
                            </v-btn>
                        </template>
                        <v-card v-if="dialog_process">
                            <v-card-text class="gold white--text pa-0 pa-2 px-3 mb-4">
                                <v-icon dark left>mdi-cogs</v-icon>
                                <span class="body-1 text-uppercase">{{$t('transactions.dialog.processing')}}</span>
                            </v-card-text>
                            <v-card-text v-for="([key, value],i) in Object.entries(processing_state)" :key="i" class="py-0">
                                <v-row no-gutters v-if="key !== 'complete'"
                                       class="lighten-3 rounded px-2 py-1 mb-1" :class="value === 'COMPLETE' ? 'green' : 'grey'"
                                >
                                    <v-col cols="6"
                                           v-html="$t('transactions.process.' + key)"/>
                                    <v-col cols="6" align="right">
                                        <v-icon v-if="value === 'COMPLETE'" color="green">{{ 'mdi-check-circle' }}</v-icon>
                                        <v-icon v-else-if="value === 'NOT_STARTED'" color="info">{{ 'mdi-pause-circle-outline' }}</v-icon>
                                    </v-col>
                                    <v-col cols="12" v-if="value === 'IN_PROGRESS'">
                                        <v-progress-linear
                                            color="info"
                                            indeterminate
                                            rounded
                                            height="6"
                                        ></v-progress-linear>
                                    </v-col>
                                </v-row>

                            </v-card-text>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-col>
            </v-row>
        </v-container>
    </section>
</template>

<script>
import {uploadBankStatement, processBankStatement, getBankTransactionProcessingProgress} from "@/api/transactions";
import Card from "@/components/Card";

export default {
    name: "ContentManagementDialog",
    components: {
        Card
    },
    props: {
        edit: Boolean,
        id: undefined,
        card_item: undefined
    },
    data:()=>({
        content: undefined,
        file: null,
        filter: {
            page: 1,
            limit: 50,
            toolbar_filter: {}
        },

        transactions: {
            items: [],
            total_count: 0
        },

        dialog_process: false,
        processing_state: {},
        interval: 0,
        can_process: false
    }),
    created() {
        if(this.edit) {
            let title = this.$t('transactions.dialog.edit_content') +' '+ this.card_item.date;
            this.$parent.$emit('action', {func: 'setToolbarTitle', data: title});

        } else {
            // pass
        }
    },
    watch: {
        'processing_state.complete': function(val) {
            if(val) {
                clearInterval(this.interval);
                setTimeout(function() {
                    this.dialog_process = false;
                    this.$parent.$emit('action_dialog', {func: 'closeDialog', data: 'transaction_process_done'});
                    this.$root.$emit('reload');
                }.bind(this), 2000)
            }
        },

        'filter.page':function() {
            this.$store.dispatch('getBankTransactionsForStatement', this.filter)
                .then(()=>{
                    this.transactions = this.$store.getters.getBankTransactionsForStatement;
                })
        }
    },
    computed: {
        getTransactions() {
            return this.transactions;
        }
    },
    methods: {
        upload_bank_statement() {
            this.$root.$emit('overlay', true);
            let formData = new FormData();
            formData.append('file', this.$refs.transactions.files[0]);

            uploadBankStatement(formData).then(() => {
                this.$root.$emit('overlay', false);
                this.$store.dispatch('getBankTransactionsForStatement', this.filter)
                    .then(()=>{
                        this.transactions = this.$store.getters.getBankTransactionsForStatement;
                        this.can_process = true;
                    })
            })
        },
        process_bank_statement() {
            processBankStatement().then((response)=>{
                if(response.data) {
                    this.get_processing();
                }

            })
        },

        get_processing() {
            clearInterval(this.interval);

            this.interval = setInterval(()=>{
                getBankTransactionProcessingProgress().then((response)=>{
                    this.processing_state = response.data;
                })
            }, 1000);
        }
    },
    beforeDestroy() {
        clearInterval(this.interval);
    },
    destroyed() {
        this.$store.dispatch('clearBankTransactionsForStatement');
    }
}
</script>